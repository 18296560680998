import api from "@requests/request";
import { email_response_templates_path } from "@routes/routes";
import { ProposalyType } from "@tsTypes/proposaly";

export async function fetchEmailResponseTemplates(company_id: string | number, request) {
  const response = await api.get(email_response_templates_path(), {
    params: {
      request_id: request?.id,
      company_id: company_id,
    },
  });
  return response.data.email_response_templates;
}

export async function submitProposalContact({
  message,
  proposalyType,
  proposalyId,
  status,
}: {
  message?: string;
  proposalyType: ProposalyType;
  proposalyId: number;
  status: number;
}) {
  const data = {
    message: message,
    proposaly_type: proposalyType,
    proposaly_id: proposalyId,
    status,
  };

  const responseJson = await api.post("/notifications/proposal_contact", data);

  return responseJson;
}

// massAdvanceProposals
export async function updateProposalStatuses(data: {
  proposaly_type: ProposalyType;
  proposaly_ids: number[];
  status: number;
  message: string;
}) {
  const responseJson = await api.post("/proposals/status", data);

  return responseJson;
}

export async function sponsorShareProposal({ message, proposal_id, email }) {
  const data = {
    message,
    proposal_id,
    email,
  };
  const responseJson = await api.post("/notifications/sponsor_share_proposal", data);

  return responseJson;
}

export async function fetchProposalsSortedByScore({ rfp_id, status, type }) {
  const responseJson = await api.get("/proposals/sort_proposals_by_score", {
    params: {
      rfp_id,
      status,
      is_user_score: type === "My Score",
    },
  });
  return responseJson.data.proposals;
}
